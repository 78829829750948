// Common reused queries

// User, isAdmin, access
export const CURRENT_USER = `
  query {
    currentUser {
      id
      email
      firstName
      lastName
      isAdmin
      access {
        id
        localAuthority {
          id
          slug
        }
        role
      }
      preferences {
        id
        key
        value
      }
    }
  }
`;

export const LOCAL_AUTHORITY = `
query localAuthority($id: ID, $slug: String) {
  localAuthority(id: $id, slug: $slug) {
    id
    name
    slug
    country
    governmentRegion {
      id
      name
    }
  }
}
`;

export const MATERIAL_CATEGORIES = `
  query {
    kerbsideMaterialCategories {
      id
      name
      materials {
        id
        name
      }
      locatorMaterialCategory {
        id
        colour
      }
    }
  }
`;
